import { faLongArrowAltRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { BASE_URL } from '../../constants/Constant'

const CmsHeader = ({title,img}) => {
  console.log(title,img,"cms header");
  
  return (
    <>
      <section className="inner-banner homepad position-relative" style={{ background: `url(${img?BASE_URL+'/'+img:'images/about-us-title-img.jpg'}) center`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
        <div className="container position-relative py-4 py-md-5">
          <div className="row align-items-center">
            <div className="col-sm-12 py-md-5">
              <div className="text-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-3 font-size-16 justify-content-center">
                    <li className="breadcrumb-item"><a href="#" className="font-weight-bold text-danger text-decoration-none">Home</a></li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} className='text-white mx-2 mx-md-3'/></li>
                    <li className="breadcrumb-item active text-white" aria-current="page">{title}</li>
                  </ol>
                </nav>
                <h1 className="font-weight-bold text-white font-size-70 text-capitalize mb-0 line-hight-1">{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CmsHeader