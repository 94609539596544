
// export const BASE_URL = 'http://localhost:8086'
// export const BASE_URL = 'http://162.240.239.81:8086'

export const BASE_URL = 'https://api.panoralands.in/'

export const s3imageUrl = 'https://panora-s3-bucket.s3.ap-south-1.amazonaws.com'
export const END_POINTS = {
    GET_CITY: BASE_URL + '/api/city/get',
    GET_BLOGS: BASE_URL + '/api/cms/blog/blogs',
    GET_PROPERTY_TYPE: BASE_URL + '/api/propertytype/get',
    GET_LISTING_TYPE: BASE_URL + '/api/listingtype/get',
    GET_FEATHER_PROPERTY_TYPE: BASE_URL + '/api/property/get-web',
    GET_HEADER_FOOTER: BASE_URL + '/api/cms/web-content/get',
    GET_HOME: BASE_URL + '/api/cms/home-content/get',
    GET_WHY_US: BASE_URL + '/api/cms/why-choose-us/get',
    GET_BANNER: BASE_URL + '/api/cms/banner/get',
    GET_TESTIMONIAL: BASE_URL + '/api/cms/testimonials/get',
    GET_PARTNERS: BASE_URL + '/api/cms/auth-partners/get',
    GET_BUILDERS: BASE_URL + '/api/cms/top-builders/get',
    GET_ABOUT: BASE_URL + '/api/cms/about-content/get',
    GET_TEAM: BASE_URL + '/api/cms/team/get',
    ADD_INQUIRY: BASE_URL + '/api/users/add',
    GET_TERMS: BASE_URL + '/api/cms/terms/get',
    GET_PRIVACY: BASE_URL + '/api/cms/policy/get',
    GET_CONTACT: BASE_URL + '/api/cms/contact/get',
    GET_TESTIMONIAL_CONTENT: BASE_URL + '/api/cms/testimonial-seo/get',
    GET_PROPERTY_BY_URL: BASE_URL + '/api/property/get-web-by-url',
    GET_PROPERTY_BANNER: BASE_URL + '/api/property-banner/get',
}
