import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import CTA from '../components/common/CTA';
import { BASE_URL } from '../constants/Constant';

const BlogDetails = () => {
  const { slug } = useParams(); // Get slug from the URL
  const [blog, setBlog] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [contact, setContact] = useState({});

  // Fetch blog details based on the slug
  useEffect(() => {
    fetch(`${BASE_URL}/api/cms/blog/blogs/${slug}`)
      .then((response) => response.json())
      .then((data) => {
        setBlog(data.blog);
        document.title = data.blog.seoTitle; // Update the title dynamically
      })
      .catch((error) => console.error('Error fetching blog data:', error));

  }, [slug]);


  return (
    <>
      <Helmet>
        <title>{blog?.seoTitle}</title>
        <meta name="keywords" content={blog?.seoKeyword || contact.seoKeyword} />
        <meta name="description" content={blog?.seoDescription || contact.seoDescription} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Header active="blogs" />
      <main>
        {blog ? (
          <div className='container my-3'>
            <div className="blog-detail">
              <img src={BASE_URL + '/' + blog.banner} alt={blog.title} className="blog-banner mb-3 w-100" />
              <div className='d-flex justify-content-between'>
                <h1>{blog.title}</h1>
                <p>{blog.createdAt}</p>

              </div>
              <div dangerouslySetInnerHTML={{ __html: blog.description }} />
            </div>
          </div>
        ) : (
          <p>Loading blog details...</p>
        )}
        {blog?.relatedBlogs && blog?.relatedBlogs.length > 0 && (
          <div className="container mb-5">
            <h2 className="text-center mt-5">Related Blogs</h2> 
            <div className="row customer-reviews">
              {blog.relatedBlogs.length === 0 ? (
                <div className="w-100 text-center mt-5">
                  <h4>No Related Blogs Found</h4>
                </div>
              ) : (
                blog.relatedBlogs.map((t) => (
                  <div key={t._id} className="item col-12 col-md-6 col-lg-4 mt-3 mt-lg-4">
                    <div className="position-relative">
                      <div className="p-4 bg-light min-h-testiminial">
                        <div className="">
                          <img
                            src={BASE_URL + '/' + t.banner}
                            className="img-fluid w-100"
                            alt={t.title}
                          />
                        </div>
                        <h4 className="lh-base my-2 fix-testie-content">{t.title}</h4>
                        <p className="font-size-16 text-secondary lh-base mb-4 fix-testie-content">
                          {t.shortDesc}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-secondary font-size-15">
                            {new Date(t.createdAt).toLocaleDateString()}
                          </div>
                          <Link to={`/blogs/${t.slug}`} className="text-danger font-size-15">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        )}


        <CTA />
        <Footer />
      </main>
    </>
  );
};

export default BlogDetails;
