import React, { useEffect } from 'react'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import HeroSlider from '../components/home/HeroSlider'
import Category from '../components/home/Category'
import FeaturedProperty from '../components/home/FeaturedProperty'
import About1 from '../components/common/About1'
import WhyUs from '../components/home/WhyUs'
import Testimonials from '../components/home/Testimonials'
import Partner from '../components/common/Partner'
import RecentlySold from '../components/common/RecentlySold'
import CTA from '../components/common/CTA'
import TopBuilders from '../components/common/TopBuilders'
import About2 from '../components/common/About2'
import SearchResult from '../components/search/SearchResult'
import CmsHeader from '../components/common/CmsHeader'
import ContactForm from '../components/contact/ContactForm'
import Map from '../components/contact/Map'
import Team from '../components/about/Team'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTestimonialData } from '../actions/testimonialActions'
import { fetchTeamData } from '../actions/teamActions'
import { fetchAboutData } from '../actions/aboutActions'
import { fetchPrivacyData } from '../actions/privacyActions'
import { Helmet } from 'react-helmet'


const Privacy = () => {

  const dispatch = useDispatch();
  const privacy = useSelector((state) => state.privacy);

  useEffect(() => {

    dispatch(fetchPrivacyData());
    // dispatch(fetchTeamData());
    // dispatch(fetchAboutData());



  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>{privacy?.data?.seoTitle}</title>
        <meta name="keywords" content={privacy?.data?.seoKeyword} />
        <meta name="description" content={privacy?.data?.seoDescription} />
        <meta property="og:title" content={privacy?.data?.seoTitle} />
        <meta property="og:description" content={privacy?.data?.seoDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Header />
      <main>
        <CmsHeader title="Privacy Policy" img={privacy?.data?.bgImage} />
        <div className='container mt-5'>
          <div dangerouslySetInnerHTML={{ __html: privacy?.data?.policy }} style={{ outline: "none" }}></div>
        </div>
        <CTA />
        <Footer />
      </main>
    </>
  )
}

export default Privacy